<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <vab-query-form>
      <div style="margin: 10px 0">
        <el-button
          class="leftBtn"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit('', 'add')"
        >
          创建
        </el-button>
      </div>
    </vab-query-form>
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :table-columns="tableColumns"
      :table-data="tableData"
      :w-theight="320"
      :table-loading="tableLoading"
      @fetchData="fetchData"
    />
    <addEdit ref="edit" @fetch-data="clickSearch" />
    <!-- 预览 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      size="tiny"
      title="查看大图"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <img alt="" class="imgPreviewDialog" :src="currentImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { queryDataList } from "@/project/eztree/baseApi/MessageApi";
import axios from "axios";
import store from "@/store";

export default {
  name: "Map",
  components: {
    addEdit: () => import("./addEdit"),
  },
  data() {
    return {
      queryForm: {},
      dialogVisible: false,
      currentImageUrl: "",
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "title",
          label: "标题",
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "用户信息",
          key: "info",
          align: "center",
          width: "120px",
          render: function (h, params) {
            return h("div", {
              domProps: {
                innerHTML: `<div style="line-height: 22px;">
                              <span">${
                                (params.row.user_info && params.row.user_info.nickname) ||
                                "-"
                              }</span>
                            </div>`,
              },
            });
          },
        },
        {
          label: "消息类型",
          align: "center",
          prop: "message_type",
          render: function (h, params) {
            return h("div", {
              domProps: {
                innerHTML: `<div style="line-height: 22px;">
                              <span">${
                                params.row.message_type == "1"
                                  ? "系統"
                                  : params.row.message_type == "0"
                                  ? "用户"
                                  : params.row.message_type
                              }</span>
                            </div>`,
              },
            });
          },
        },
        {
          label: "标题",
          prop: "title",
          align: "left",
        },
        {
          label: "简介",
          align: "left",
          prop: "description",
        },
        {
          label: "图片",
          key: "cover_image",
          align: "center",
          width: "160px",
          render: function (h, params) {
            return h("div", [
              h("img", {
                attrs: {
                  style: "height:100px;",
                  src: params.row.cover_image,
                  alt: "图片走丢了",
                },
              }),
            ]);
          },
        },
        {
          label: "创建时间",
          prop: "created_at",
        },
        {
          label: "操作",
          fixed: "right",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {},
              },
              [
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.dialogVisible = true;
                        this.currentImageUrl = params.row.cover_image;
                      },
                    },
                  },
                  "查看大图"
                ),
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.$refs["edit"].showEdit(params.row, "edit");
                      },
                    },
                  },
                  "编辑"
                ),
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.$refs["edit"].showEdit(params.row, "view");
                      },
                    },
                  },
                  "详情"
                ),
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.$refs["edit"].showEdit(params.row, "delete");
                      },
                    },
                  },
                  "删除"
                ),
              ]
            );
          },
        },
      ];
      return columns;
    },
  },
  mounted() {},
  methods: {
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      queryDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$refs["edit"].showEdit(row, action);
    },
  },
};
</script>
