var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "common-dialog",
      attrs: {
        title: _vm.title,
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        "append-to-body": "",
        height: "10vh",
        "max-height": "680",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _vm.pageState != "view"
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v(" 确 定 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [
                _vm._v(
                  " " + _vm._s(_vm.pageState == "add" ? "取 消" : "关 闭") + " "
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.dialogFormVisible
        ? _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "activitiesForm",
                  attrs: {
                    "label-position": "right",
                    "label-width": "80px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题:", prop: "title" } },
                    [
                      _vm.pageState != "view"
                        ? _c("el-input", {
                            staticClass: "width200",
                            attrs: {
                              clearable: "",
                              maxlength: "50",
                              "show-word-limit": "",
                              placeholder: "请输入标题",
                            },
                            model: {
                              value: _vm.form.title,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "title",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.title",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.form.title))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "简介:", prop: "description" } },
                    [
                      _vm.pageState != "view"
                        ? _c("el-input", {
                            staticClass: "width200",
                            attrs: {
                              clearable: "",
                              "show-word-limit": "",
                              placeholder: "请输入简介",
                              maxlength: "100",
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "description",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.description",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.form.description))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片:", prop: "cover_image" } },
                    [
                      _c(
                        "nxp-upload",
                        _vm._b(
                          {
                            ref: "nxpUpload",
                            attrs: {
                              uploadItem: _vm.activeMainImg,
                              pageState: _vm.pageState,
                              accessories: _vm.activeMainImg.accessories,
                            },
                            on: { getUploadList: _vm.getUploadList },
                          },
                          "nxp-upload",
                          _vm.$attrs,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容:", prop: "content" } },
                    [
                      _vm.pageState != "view"
                        ? _c("el-input", {
                            staticClass: "width200",
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              clearable: "",
                              "show-word-limit": "",
                              placeholder: "请输入内容",
                              maxlength: "500",
                            },
                            model: {
                              value: _vm.form.content,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "content",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.content",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.form.content))]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }