<template>
  <el-dialog
    class="common-dialog"
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogFormVisible"
    append-to-body
    height="10vh"
    max-height="680"
    width="600px"
    @close="close"
  >
    <div v-if="dialogFormVisible">
      <el-form
        ref="form"
        class="activitiesForm"
        label-position="right"
        label-width="80px"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="标题:" prop="title">
          <el-input
            class="width200"
            v-if="pageState != 'view'"
            v-model.trim="form.title"
            clearable
            maxlength="50"
            show-word-limit
            placeholder="请输入标题"
          ></el-input>
          <span v-else>{{ form.title }}</span>
        </el-form-item>

        <el-form-item label="简介:" prop="description">
          <el-input
            class="width200"
            v-if="pageState != 'view'"
            v-model.trim="form.description"
            clearable
            show-word-limit
            placeholder="请输入简介"
            maxlength="100"
          ></el-input>
          <span v-else>{{ form.description }}</span>
        </el-form-item>

        <el-form-item label="图片:" prop="cover_image">
          <nxp-upload
            v-bind="$attrs"
            ref="nxpUpload"
            @getUploadList="getUploadList"
            :uploadItem="activeMainImg"
            :pageState="pageState"
            :accessories="activeMainImg.accessories"
          ></nxp-upload>
        </el-form-item>
        <el-form-item label="内容:" prop="content">
          <el-input
            class="width200"
            type="textarea"
            :rows="5"
            v-if="pageState != 'view'"
            v-model.trim="form.content"
            clearable
            show-word-limit
            placeholder="请输入内容"
            maxlength="500"
          ></el-input>
          <span v-else>{{ form.content }}</span>
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <el-button v-if="pageState != 'view'" type="primary" @click="save">
        确 定
      </el-button>
      <el-button @click="close">
        {{ pageState == "add" ? "取 消" : "关 闭" }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  saveDataItem,
  queryDataDetail,
  delDataItem,
} from "@/project/eztree/baseApi/MessageApi";

import { mapText } from "@/project/eztree/baseTool/mapStr";

export default {
  name: "addEdit",
  props: {},
  components: {},
  watch: {},
  data() {
    return {
      dialogTableVisible: false,
      pageObj: {
        pageType: "components",
        selectType: "checkBox",
      },
      jsonValue: [],
      form: {
        id: "",
        title: "",
        description: "",
        cover_image: "",
        content: "",
      },
      checkValid: false,
      //   设置开始时间应不允许大于结束时间

      rules: {
        title: [{ required: true, trigger: "blur", message: "请输入标题" }],
        description: [{ required: true, trigger: "blur", message: "请输内容" }],
        cover_image: [
          {
            validator: function (rule, value, callback) {
              return callback();
            },
            required: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      pageState: "add",
      title: "",
      dialogFormVisible: false,
      activeMainImg: {
        type: "uploadFile",
        label: "图片",
        model: [],
        prop: "cover_image",
        rules: {
          required: true,
          files: true,
        },
        accessories: {
          dataType: "string",
          disabled: false,
          multiple: false,
          limit: 1,
          accept: ".jpg,.png",
          listType: "picture-card",
          fileParam: {},
        },
      },
    };
  },
  created() {},
  methods: {
    getUploadList(uploadItem, result) {
      if (uploadItem.accessories.accept == ".xlsx") {
        this.form[uploadItem.prop] = result[0].data;
      }
      if (uploadItem.accessories.accept == ".jpg,.png") {
        this.form[uploadItem.prop] = result[0].url;
      }
      console.log("getUploadList", uploadItem, result, this.form);
    },
    // 打开弹框
    showEdit(row, action) {
      let params = Object.assign({});
      params.id = row.id;
      if (action == "delete") {
        this.$confirm("确认删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delDataItem(params).then(({ code, data }) => {
              if (code == "200") {
                this.$baseMessage("删除成功", "success", "vab-hey-message-success");
                this.$emit("fetch-data");
              }
            });
          })
          .catch(() => {});
        return false;
      }
      this.pageState = action;
      this.title =
        action == "add"
          ? "创建"
          : action == "edit"
          ? "编辑"
          : action == "view"
          ? "查看"
          : "";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        if (action != "add") {
          queryDataDetail(params).then(({ code, data }) => {
            this.form = Object.assign({}, data);
            this.$refs.nxpUpload.callBackItemImg(JSON.stringify([this.form.cover_image]));
          });
        }
      });
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs.nxpUpload.validateSubmit();
      this.$refs["form"].validate((valid) => {
        Promise.all([this.$refs.nxpUpload.checkValid, valid])
          .then((values) => {
            console.log("values", values);
            //都调成功以后执行的操作
            if (values.every((item) => !!item)) {
              console.log("params", this.form);
              saveDataItem(this.form).then(({ code, data }) => {
                if (code == "200") {
                  this.$baseMessage("操作成功", "success", "vab-hey-message-success");
                  this.$emit("fetch-data");
                  this.close();
                }
              });
            } else {
              this.$baseMessage("必填项未填写", "error", "vab-hey-message-error");
            }
          })
          .catch((err) => {
            // 抛出错误信息
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
